<template>
  <div class="members-manage">
    <PageTitle
      style="margin-bottom: 25px"
      title="補助會員管理"
      :hideBtn="false"
      :hideBtn1="true"
      btn2="匯入"
      @btn2Click="showUploadDialog = true"
    />

    <FiltersContainer>
      <el-input
        v-model="search.name"
        clearable
        placeholder="輸入姓名"
        class="flex-grow-1"
        @keypress.enter.native="refresh(true)"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
    </FiltersContainer>

    <section class="flex justify-end items-end" style="gap: 12px">
      <TableFilter
        style="margin-left: 15px"
        :tableName="tableName"
        :options="subsidyMemberManageFilterOptions"
        @update="updateTableFilter"
      />
    </section>

    <section v-loading="loading">
      <!-- <el-table empty-text="暫無數據" :data="membersList" @row-click="$router.push('/members/member-profile')"> -->
      <el-table v-if="!tableRefresh" empty-text="暫無數據" :data="membersList">
        <EmptyBlock slot="empty" />
        <el-table-column
          prop="name"
          label="姓名"
          fixed
          align="center"
        />
        <el-table-column
          v-if="showColumn('city')"
          prop="city"
          label="營運縣市"
          align="center"
        />
        <el-table-column
          v-if="showColumn('memberId')"
          prop="memberId"
          label="個案驗證碼"
          align="center"
        />
        <el-table-column
          v-if="showColumn('operatingUnit')"
          prop="operatingUnit"
          label="運營單位"
          align="center"
        />
        <el-table-column
          v-if="showColumn('level')"
          prop="level"
          label="等級"
          align="center"
        />
        <el-table-column
          v-if="showColumn('status')"
          prop="status"
          label="補助狀態"
          align="center"
        >
          <template slot-scope="scope">
            {{ transferStatus(scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="showColumn('createdAt')"
          prop="createdAt"
          label="建立時間"
          align="center"
        >
          <template slot-scope="scope">
            {{ createdAtFormat(scope.row.createdAt) }}
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-if="!loading"
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="membersCount"
        @pageChange="changePage"
      />
    </section>

    <!-- ANCHOR template upload-->
    <el-dialog
      title="匯入檔案"
      :visible.sync="showUploadDialog"
      :close-on-click-modal="false"
      width="30%"
      @close="resetForm"
    >
      <el-form
        ref="form"
        v-loading="uploadLoading"
        :model="uploadFormData"
        :rules="uploadRules"
        label-position="top"
      >
        <el-form-item
          label="檔案上傳"
        >
          <InputFile
            :modelValue.sync="uploadFormData.file"
            accept="text/csv"
            @errorFileType="$message.error('檔案格式錯誤')"
            @errorFileSize="$message.error('檔案大小超過 10 mb')"
            @update:modelValue="onUpdateFile"
          >
            <template v-slot:tip="{ size }">
              <div class="file-tips">
                <p class="mb-1">
                  注意事項：檔案大小必須小於 {{ size }}MB，格式僅支援 CSV 檔案
                </p>
                <span class="underline cursor-pointer" @click="downloadTemplate">下載範例格式</span>
              </div>
            </template>
          </InputFile>
        </el-form-item>
      </el-form>

      <div v-if="isVerifyData">
        <p>資料檢查結果如下：</p>
        <p>新增：{{verifyResult.createCount}} 筆</p>
        <p>修改：{{verifyResult.updateCount}} 筆</p>
        <p>刪除：{{verifyResult.deleteCount}} 筆</p>
        <p>格式錯誤：{{verifyResult.failLogs.length}} 筆</p>
      </div>

      <div v-if="verifyResult.failLogs.length > 0">
        <p class="underline cursor-pointer" @click="downloadFailLog">錯誤資訊下載</p>
      </div>

      <div slot="footer">
        <el-button
          class="btn"
          plain
          @click="showUploadDialog = false"
        >
          取消
        </el-button>
        <el-button
          class="btn"
          type="primary"
          :disabled="noContent"
          @click="handleConfirm"
        >
          執行匯入
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EmptyBlock from '@/components/EmptyBlock.vue'
import {
  mapGetters
} from 'vuex'
import {
  subsidyMemberManageFilterOptions
} from '@/config/table'
import {
  GetSubsidyMembers,
  GetSubsidyMembersCount,
  BatchImportSubsidyMember,
  VerifyBatchImportSubsidyMember
} from '@/api/subsidyMember'
import {
  useTableFilter
} from '@/use/tableFilter'

// Utils
import {pageStartIndex} from '@/utils/table'

import {defineComponent} from 'vue'
import {useTagStore} from '../Marketing/components/useTagStore'
import {useCompany} from '@/use/company'
import TableFilter from "@/components/Button/TableFilter.vue";
import dayjs from "dayjs";
import InputFile from "@/components/Form/InputFile.vue";
import formUtils from "@/utils/form";
import { readDataFromCsv, exportDataToCsv } from "@/utils/csv";
import { ExportMoreSheetExcel } from '@/utils/excel'
import { importType } from '@/config/subsidyMember'

export default defineComponent({
  name: 'SubsidyMembersManage',
  components: {
    InputFile,
    TableFilter,
    EmptyBlock,
  },
  setup () {
    const tableName = 'memberManage'
    const { tags, fetchTagsOnMounted } = useTagStore()
    const { useCompanyContactMenu, getShopContactMenuInfo, orgTaxId } = useCompany()
    const {
      tableRefresh,
      updateTableFilter,
      showColumn,
      getTableFilterConfig,
      tableFilter,
    } = useTableFilter(tableName, subsidyMemberManageFilterOptions)
    // const tableRefresh = ref(false)

    fetchTagsOnMounted()

    return {
      tags,
      useCompanyContactMenu,
      getShopContactMenuInfo,
      orgTaxId,
      tableRefresh,
      tableName,
      tableFilter,
      showColumn,
      subsidyMemberManageFilterOptions,
      updateTableFilter,
      getTableFilterConfig,
    }
  },
  data: () => ({
    membersCount: 0,
    search: {
      name: '',
    },
    membersList: [],

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    // Dialog
    loading: false,

    // upload dialog
    showUploadDialog: false,
    uploadFormData: {
      file: {
      },
    },
    uploadLoading: false,
    isVerifyData: false,
    uploadData: [],
    verifyResult: {
      createCount: 0,
      deleteCount: 0,
      updateCount: 0,
      failLogs: []
    }
  }),
  computed: {
    formUtils() {
      return formUtils
    },
    ...mapGetters([
      'shop',
      'userPlanFeature',
      'userFeatures',
      'orgId',
    ]),
    queryPage () {
      return this.$route.query.page || 1
    },
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    uploadRules () {
      // const file = this.uploadFormData.file
      // console.log(file.url)
      // if (file.url) {
      //   return {
      //     fileName: [noEmptyRules(), lengthRules(0, 20)],
      //     fileDescription: [lengthRules(0, 50)],
      //     // images: [noEmptyRules()],
      //     // memberNote: [noEmptyRules()],
      //     // note: [noEmptyRules()],
      //   }
      // }
      // return {
      //   fileName: [lengthRules(0, 20)],
      //   fileDescription: [lengthRules(0, 50)],
      // }
      return {}
    },
    noContent () {
      return !this.uploadFormData.file?.url && !this.uploadFormData.file?.id
    },
  },
  watch: {
    async queryPage () {
      this.tableOptions.page = Number(this.queryPage)
      await this.refresh()
    },
  },
  beforeMount () {
    this.tableOptions.page = Number(this.queryPage)
  },
  async mounted () {
    this.tableFilter = this.getTableFilterConfig()
    this.tableOptions.page = Number(this.queryPage)
    await this.refresh()
  },
  // ANCHOR Methods
  methods: {
    async changePage (page) {
      this.tableOptions.page = page
      this.$router.push({
        name: 'SubsidyMembersManage',
        query: {
          page: page || 1,
        },
      })
      // await this.refresh()
    },
    async refresh (search = false) {
      this.loading = true
      if (search) { this.tableOptions.page = 1 }
      if (!search) { this.tableOptions.page = Number(this.queryPage) }
      this.getMembers()
      this.getMemberCount()

      this.loading = false
    },
    // ANCHOR 取得會員資料
    async getMembers () {
      try {
        this.membersList = (
          await GetSubsidyMembers({
            shopId: this.shop,
            start: this.pageStartIndex,
            limit: this.tableOptions.pageLimit,
            name: this.search.name === '' ? undefined : this.search.name,
            memberId: this.search.memberId === '' ? undefined : this.search.memberId,
          })
        )
      } catch (error) {
        console.log(error)
        this.exportError = true
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    async getMemberCount () {
      try {
        this.membersCount = await GetSubsidyMembersCount({
          shopId: this.shop,
          name: this.search.name === '' ? undefined : this.search.name,
          memberId: this.search.memberId === '' ? undefined : this.search.memberId,
        })
      } catch (error) {
        console.log(error)
        this.exportError = true
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    createdAtFormat (date) {
      return dayjs(date).format('YYYY/MM/DD HH:mm:ss')
    },
    transferStatus (status) {
      const statusDict = {
        available: '可補助',
        termination: '不可補助',
      }
      return statusDict[status] || 'N/A'
    },
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.uploadFormData = {
        file: {},
      }
      this.verifyResult = {
        createCount: 0,
        deleteCount: 0,
        updateCount: 0,
        failLogs: []
      }
    },
    async onUpdateFile (value) {
      if (!value.raw) {
        this.isVerifyData = false
        this.verifyResult = {
          createCount: 0,
          deleteCount: 0,
          updateCount: 0,
          failLogs: []
        }
        return
      }
      const data = await readDataFromCsv(value.raw)
      console.log('data', data)
      // ---- check data
      this.uploadData = this.dataTransfer(data)
      // await this.verifyBatchImport(dataTrans)
    },
    dataTransfer(csvData) {
      return csvData.map((data) => {
        const address = []
        for(let i = 1; i <= 5; i++) {
          const value = data[`常用地址${i}`]
          if (value) {
            address.push(value)
          }
        }
        const findImportType = importType.find(item => item.label === data['匯入需求'])
        const type = findImportType ? findImportType.value : 'create'

        return {
          city: data['縣市'],
          operatingUnit: data['運營單位'],
          identityCard: (data['身分證字號後四碼'] || '').toString().padStart(4, '0'),
          birthday: data['出生月日'],
          allowReservationCount: parseInt(data['可預約趟數'] || '8'),
          name: data['姓名'] || '',
          address,
          status: data['補助狀態'] === '可補助' ? 'available' : 'unavailable',
          level: data['身障等級'] || 'C',
          type
        }
      })
    },
    async verifyBatchImport(data) {
      this.uploadLoading = true
      try {
        const res = await VerifyBatchImportSubsidyMember(this.shop, {
          data
        })
        this.verifyResult = {
          ...res
        }
        this.isVerifyData = true
      } catch (error) {
        this.$message.error(error.message)
      } finally {
        this.uploadLoading = false
      }
    },
    async handleConfirm () {
      this.uploadLoading = true
      try {
        const verify = await VerifyBatchImportSubsidyMember(this.shop, {
          data: this.uploadData
        })
        this.verifyResult = {
          ...verify
        }
        if (verify.failLogs.length > 0) {
          this.$message.error('匯入失敗')
          return
        }
        await BatchImportSubsidyMember(this.shop, {
          data: this.uploadData
        })
        this.$message.success('匯入成功')
        this.showUploadDialog = false
        this.resetForm()
        this.refresh()
      } catch (error) {
        this.$message.error(error.message)
      } finally {
        this.uploadLoading = false
      }
    },
    downloadTemplate() {
      exportDataToCsv([{
        '縣市': '台北市',
        '運營單位': '台北市政府',
        '姓名': '王小明',
        '身分證字號後四碼': '1234',
        '出生月日': '0101',
        '可預約趟數': '8',
        '常用地址1': '台北市信義區信義路五段7號',
        '常用地址2': '台北市信義區信義路五段7號',
        '常用地址3': '',
        '常用地址4': '',
        '常用地址5': '',
        '補助狀態': '可補助',
        '身障等級': 'C',
        '匯入需求': '新增'
      }], '範例格式')
    },
    downloadFailLog() {
      ExportMoreSheetExcel([{
          sheetName: '匯入失敗資料',
          data: this.verifyResult.failLogs.map(item => {
            const findImportType = importType.find(importType => importType.value === item.type)
            return {
              // 錯誤行數: item.index + 1,
              錯誤訊息: item.errorMessages,
              匯入種類: findImportType ? findImportType.label : '無',
            }
          }),
      }], '匯入失敗資料')
    }
  },
})
</script>

<style scoped lang="postcss">
.login-auth {
  @apply flex justify-center gap-[4px];
}

</style>
