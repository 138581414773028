const saveData = (function () {
  const a = document.createElement('a')
  a.target = '_blank'

  // a.style = "display: none"
  return function (url, fileName) {
    a.setAttribute('download', fileName)
    a.href = url
    // a.rel = 'noopener noreferrer'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    // a.click()
    // document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }
}())

export const readDataFromCsv = async (fileRaw) => {
  const reader = new FileReader()
  return new Promise((resolve) => {
    const onLoad = (event) => {
      const rows = event.target.result.split("\r\n");
      const headers = rows[0].split(",");
      const data = [];
      for (let i = 1; i < rows.length; i++) {
        const cells = rows[i].split(",");
        if (cells.length > 1) {
          const row = {};
          for (let j = 0; j < headers.length; j++) {
            row[headers[j]] = cells[j];
          }
          data.push(row);
        }
      }
      resolve(data)
    }
    reader.onload = onLoad
    reader.readAsText(fileRaw);
  })
}

export const exportDataToCsv = async (data, fileName) => {
  const headers = Object.keys(data[0]);
  const csv = headers.join(",") + "\r\n" + data.map(row => headers.map(header => row[header]).join(",")).join("\r\n");
  const blob = new Blob(['\uFEFF'+csv], { type: 'text/csv; charset=utf-18' });
  const url = URL.createObjectURL(blob);
  saveData(url, fileName);
}
