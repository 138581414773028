export const importType = [
  {
    label: '新增',
    value: 'create',
  },
  {
    label: '修改',
    value: 'update',
  },
  {
    label: '刪除',
    value: 'delete',
  },
]
